import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { formatDateForTimestamp } from "~/scripts/useful-functions"
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime";

export class TrackingService {
  constructor(private axios: NuxtAxiosInstance, private config: NuxtRuntimeConfig) {}

  async postTrackingEvent(event: object, route, cookies, page, window?): Promise<any | null> {
    const trackingServiceUrl = this.config.v4Host + '/api/v3/tracking'

    const defaultEventDetails = {
      url: {
        host: this.config.frontendHost,
        path: route.path
      },
      ...(window ? { 'user_agent': window.navigator.userAgent } : {}),
      'session_id': cookies.get('PHPSESSID') || null,
      timestamp: formatDateForTimestamp()
    }

    let query = route.query || [];
    query.landing_path = page.uri
    query.landing_source = 'cms'

    try {
      const response = await this.axios.post(trackingServiceUrl, {
          events: [{ ...defaultEventDetails, ...event }]
        }, {
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        params: route.query,
        withCredentials: true
      })

      if (response && response.data && response.data.track_id) {
        window.anyvan_track_id = response.data.track_id
      }

      if (response.status === 200) {
        return response
      }
    } catch (err) {
      return null
    }
  }
}


