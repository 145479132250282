import { NuxtAxiosInstance } from "@nuxtjs/axios"
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime"
import { CONTACT_INFO } from "~/constants/contact-info"

type PhoneSource = 3 | 4

const getFilteredNumber = (number: any) => {
  if (!number) {
    return number
  }

  return number.replace(/\D/g, "")
}

const getFormattedNumber = (number: any) => {
  if (!number) {
    return number
  }

  // Is phone number formatted already?
  if (number.trim().includes(" ")) {
    return number
  }

  const cleanNumber = number.replace(/\D/g, "")
  const length = cleanNumber.length

  return `${cleanNumber.slice(0, length - (length - 3))} ${cleanNumber.slice(
    length - (length - 3),
    length - 4
  )} ${cleanNumber.slice(length - 4, length)}`
}

const thirtySeconds = 30000

export class PhoneNumberService {
  private axios: NuxtAxiosInstance
  private config: NuxtRuntimeConfig

  constructor(axios: NuxtAxiosInstance, config: NuxtRuntimeConfig) {
    this.axios = axios
    this.config = config
  }

  getFormattedPhoneNumberPair(phoneString: string | undefined): PhoneNumberPair {
    return {
      base: getFilteredNumber(phoneString),
      baseRaw: getFilteredNumber(phoneString),
      extension: "",
      formatted: getFormattedNumber(phoneString)
    }
  }

  getFormattedPhoneNumberPairFromResponse(response: any): PhoneNumberPair | null {
    if (!(response.status === 200 && response.data && response.data.national)) {
      return null
    }

    return {
      base: response.data.full_extended,
      baseRaw: response.data.full,
      extension: response.data.extension,
      formatted: getFormattedNumber(response.data.national)
    }
  }

  async updatePhoneNumber(page, route, nonce: string): Promise<PhoneNumberPair> {
    // URL should be on top level
    let phoneNumberPair = this.getFormattedPhoneNumberPair(page.customPhoneNumber || "")

    // We don't run the api for
    // page generation
    if (process.server) {
      return phoneNumberPair
    }

    let response = null
    const phoneNumberFromUrl = route.query.phone as string
    if (phoneNumberFromUrl && this.isValidUkPhoneNumber(phoneNumberFromUrl)) {
      phoneNumberPair = this.getFormattedPhoneNumberPair(phoneNumberFromUrl)

      try {
        response = await this.sendPersonalisedPhoneNumber(phoneNumberPair.base, page.uri, nonce, 3)

        const phoneNumberFromResponse = this.getFormattedPhoneNumberPairFromResponse(response)
        if (phoneNumberFromResponse) {
          phoneNumberPair = phoneNumberFromResponse
          this.pingPersonalisedPhone(phoneNumberPair.baseRaw)
        }
      } catch (e) {
        console.log("There was an error", e)
      }

      return phoneNumberPair
    }

    // Landing page phone number is the second
    if (phoneNumberPair.base) {
      try {
        response = await this.sendPersonalisedPhoneNumber(phoneNumberPair.base, page.uri, nonce, 4)

        const phoneNumberFromResponse = this.getFormattedPhoneNumberPairFromResponse(response)
        if (phoneNumberFromResponse) {
          phoneNumberPair = phoneNumberFromResponse
          this.pingPersonalisedPhone(phoneNumberPair.baseRaw)
        }
      } catch (e) {
        console.log("There was an error", e)
      }
      return phoneNumberPair
    }

    // We use contact info as default
    // but we ask for the locale one.
    if (CONTACT_INFO.supportNumber) {
      phoneNumberPair = this.getFormattedPhoneNumberPair(CONTACT_INFO.supportNumber)
    }

    try {
      response = await this.getPersonalisedPhoneNumber()

      const phoneNumberFromResponse = this.getFormattedPhoneNumberPairFromResponse(response)
      if (phoneNumberFromResponse) {
        phoneNumberPair = phoneNumberFromResponse
        this.pingPersonalisedPhone(phoneNumberPair.baseRaw)
      }
    } catch (e) {
      console.log("There was an error", e)
    }

    return phoneNumberPair
  }

  protected getPersonalisedPhoneNumber(): Promise<any> {
    const personalisedPhoneNumberUrl =
      this.config.v4Host + "/ng/api/v3/actions/get-personalised-number"
    return this.axios.get(personalisedPhoneNumberUrl, {
      headers: {
        "X-Requested-With": "XMLHttpRequest"
      },
      withCredentials: true
    })
  }

  protected sendPersonalisedPhoneNumber(
    personalisedPhoneNumber: string,
    path: string,
    nonce: string,
    phoneSource: PhoneSource
  ): Promise<any> {
    const personalisedPhoneNumberUrl = this.config.v4Host + "/personalised-number"
    return this.axios.post(
      personalisedPhoneNumberUrl,
      {
        number: personalisedPhoneNumber,
        locale: "en-gb",
        landing_path: path,
        landing_source: "cms",
        fw_token: nonce,
        source: phoneSource
      },
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        withCredentials: true
      }
    )
  }

  protected pingPersonalisedPhone(personalisedPhoneNumber: string): void {
    setInterval(() => {
      const personalisedPhoneNumberPing = this.config.v4Host + "/personalised-number/ping"
      this.axios.post(
        personalisedPhoneNumberPing,
        {
          number: personalisedPhoneNumber
        },
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest"
          },
          withCredentials: true
        }
      )
    }, thirtySeconds)
  }

  isValidUkPhoneNumber(phoneNumber: string): boolean {
    const ukPhoneRegex = new RegExp(
      "^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$"
    )
    return ukPhoneRegex.test(phoneNumber)
  }
}
