import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime";

export class CreditCodeService {
  constructor(private axios: NuxtAxiosInstance, private config: NuxtRuntimeConfig) {
  }
  async getCreditCodeValue(page: Page, route): Promise<any | null> {
    if (process.server) {
      return null;
    }

    let query = route.query || [];
    if (query['credit-code']) {
      const applyResponse = await this.applyCreditCode(query['credit-code'])
      if (applyResponse.status === 200) {
        return applyResponse.data
      }
    }

    if (
      route.path.replaceAll("/", "") === "discount" ||
      route.path.replaceAll("/", "") === "angus"
    ) {
      const applyResponse = await this.applyCreditCode("RemovalsDiscount65642430")
      if (applyResponse.status === 200) {
        return applyResponse.data
      }
    }

    if (page.locationForm && page.locationForm.categoryId) {
      const getResponse = await this.getCategoryBasedCredit(page.locationForm.categoryId)
      if (getResponse.status === 200) {
        return getResponse.data
      }
    }

    return null;
  }

  applyCreditCode(creditCode) {
    // URL should be on top level
    const creditCodeUrl = this.config.v4Host + '/ng/api/v3/actions/apply-credit-code';
    return this.axios.post(creditCodeUrl, {
      credit_code: creditCode
    },{
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true
    })
  }

  getCategoryBasedCredit(categoryId) {
    // URL should be on top level
    const creditCodeUrl = this.config.v4Host + '/ng/api/v3/actions/check-credit-code/' + categoryId;
    return this.axios.get(creditCodeUrl, {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      withCredentials: true
    })
  }
}
