import { NuxtAxiosInstance } from "@nuxtjs/axios"
import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime"

const apiUrl = {
  auth: {
    profile: {
      get: "/api/v3/auth/profile"
    }
  }
}

export interface AuthProfile {
  fw_token: String
  user?: Object
}

export class AuthenticationService {
  constructor(private axios: NuxtAxiosInstance, private config: NuxtRuntimeConfig) {}

  async getProfile(): Promise<AuthProfile | null> {
    const serviceUrl = this.config.v4Host + apiUrl.auth.profile.get

    try {
      const response = await this.axios.get(serviceUrl, {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        },
        withCredentials: true
      })
      if (response.status === 200) {
        return response.data
      }
      return null
    } catch (err) {
      console.error(err)
      return null
    }
  }
}
