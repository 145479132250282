import { differenceInSeconds, format } from "date-fns"

/**
 * Function to convert date into time ago
 *
 * @param date string
 * @return string
 */
export function timeAgo(date: string): string {
  if (!date) {
    return "No date provided"
  }

  const periods = ["second", "minute", "hour", "day", "week", "month", "year", "decade"]
  const lengths = ["60", "60", "24", "7", "4.35", "12", "10"]
  const dateObj = new Date(date)
  const nowObj = new Date()

  let difference = 0
  let tense = "ago"
  // is it future date or past date
  if (nowObj > dateObj) {
    difference = differenceInSeconds(nowObj, dateObj)
  } else {
    difference = differenceInSeconds(dateObj, nowObj)
    tense = "from now"
  }

  let j = 0
  for (j = 0; difference >= parseInt(lengths[j]) && j < lengths.length - 1; j++) {
    difference /= parseInt(lengths[j])
  }

  difference = Math.round(difference)

  if (difference != 1) {
    periods[j] += "s"
  }

  return `${difference} ${periods[j]} ${tense}`
}

/**
 * Function to convert date to a timestamp
 *
 * @param date Date | string
 * @return string
 */
export function formatDateForTimestamp(date?: Date | string): string {
  if (typeof date === "string" || date instanceof String) {
    date = new Date(date)
  }
  return format(date ?? new Date(), "yyyy-MM-dd HH:mm:ss")
}

/**
 * Function to get innerText from an HTML string
 * @param html string
 * @return string
 */
export function getInnerText(html: string): string {
  if (!html) return ""
  const textContentRegex = /<[^>]*>([^<]*)<\/[^>]*>/g
  const matches = html.match(textContentRegex)

  if (!matches) {
    return ""
  }

  return matches
    .map(match => match.replace(textContentRegex, "$1"))
    .join(" ")
    .trim()
}

/**
 * Function to extract the link from WordPress HTML data
 */
export function extractHref(htmlString: string): string | null {
  if (!htmlString) return null
  const pattern = /href=["']([^"']+)["']/
  const match = htmlString.match(pattern)
  return match ? match[1] : null
}

/**
 * Function to extract the image source from WordPress HTML data
 */
export function extractImageUrl(htmlString: string): string | null {
  const regex = /src="([^"]*)"/
  const match = htmlString.match(regex)
  return match ? match[1] : null
}

export function getScreenWidth() {
  if (typeof window !== "undefined") {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  }
  return null
}

export function getv4LocaleFromI18nLocale(i18nLocale: string) {
  switch (i18nLocale) {
    case "en":
      return "en-gb"
    case "es":
      return "es-es"
    case "fr":
      return "fr-fr"
    case "de":
      return "de-de"
    case "it":
      return "it-it"
    default:
      return "en-gb"
  }
}

/**
 * RegEx explanation
 * [a-zA-Z]+:\/\/ Match any protocol
 * [a-zA-Z0-9-]+ Match the first subdomain, e.g. cms-internal
 * (?:\.[a-zA-Z0-9-]+)* Match the domain, e.g. .anyvan. Also, will match any number of subdomains, e.g. dev.cms.internal
 * \.[a-zA-Z]{2,} Match the top-level domain (TLD), e.g. .com
 */
const matchProtocolAndHost = new RegExp(
  /[a-zA-Z]+:\/\/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}/g
)

export const replaceUrlS3Helper = (
  url: string,
  destinationHost: string,
  graphqlEndpoint: string
): string => {
  if (!url) {
    return ""
  }

  if (
    graphqlEndpoint.includes("cms.anyvan.local") ||
    graphqlEndpoint.includes("dev-cms.anyvan.com")
  ) {
    return url
  }
  if (url.includes("/app/uploads")) {
    // WordPress media library images that have been auto-uploaded to S3
    return url
      .replace(matchProtocolAndHost, destinationHost)
      .replace(/\/app\/uploads/g, "/content-uploads")
  }

  if (url.includes("/app/themes/anyvan")) {
    // WordPress theme images
    return url
      .replace(matchProtocolAndHost, destinationHost)
      .replace(/\/app\/themes\/anyvan\/assets\/img/g, "/content-static/wp-theme")
  }

  return url
}
