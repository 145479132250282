export const CONTACT_INFO: ContactInfo = {
  openingHours: {
    monToFri: '8:00am - 9:00pm',
    saturday: '8:00am - 6:00pm',
    sunday: '8:00am - 6:00pm',
    bankHolidays: '8:00am - 6:00pm',
  },
  supportNumber: '02038723050',
  transportTeamNumber: '02038089083',
  sendMessageHelpOptions: [
    {
      name: 'I have a question about my existing booking',
      value: 1,
    },
    {
      name: 'I wish to amend an existing booking',
      value: 2,
    },
    {
      name: 'I wish to provide feedback',
      value: 3,
    },
    {
      name: 'I am a transport partner and I have question',
      value: 4,
    },
    {
      name: 'I wish to join AnyVan as a transport partner',
      value: 5,
    },
    {
      name: 'I wish to discuss marketing',
      value: 6,
    },
    {
      name: 'Other',
      value: 7,
    },
  ],
}

export const CAR_SALES_PHONE_TWILIO = '02038933222'
